<footer>
  <div class="container getintouch">
    <h2 class="sansserif">Get in touch</h2>
    <a href="mailto:hello@sebastienbarbier.com">hello&#64;sebastienbarbier.com</a>
    <ul>
      <li><a href="http://www.twitter.com/SebBarbier/">Twitter</a></li>
      <li><a href="https://github.com/sebastienbarbier/">Github</a></li>
      <li><a href="https://www.linkedin.com/in/sebbarbier/">Linkedin</a></li>
      <li><a href="https://instagram.com/sebastienbarbier/">Instagram</a></li>
    </ul>
  </div>
  <div class="container links">
    <p><span style="color: #999">Developed with ❤️ and lots of ☕️ by Sebastien Barbier - </span><a routerLink="/resources">Resources</a> - <a routerLink="/legal">Legal & copyright</a></p>
  </div>
</footer>
